<template>
  <div>
    <div class="row profile-outer-page-container" v-if="Profile.user != null">
      <div class="col-12 col-lg-6 offset-lg-2 profile-container">
        <h1 class="profile-title">Profile Update</h1>

        <form @submit.prevent="submit">
          <div class="form-group row mb-4">
            <label
              for="input-control-first-name"
              class="col-md-3 col-form-label"
              >First Name</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-first-name"
                autocomplete="off"
                :disabled="!user || !user.isAdmin"
                v-model="Profile.user.first"
                class="form-control"
              />
              <span
                class="hintText position-absolute"
                v-if="!user || !user.isAdmin"
              >
                To change this field contact your administrator</span
              >
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="input-control-last-name" class="col-md-3 col-form-label"
              >Last Name</label
            >
            <div class="col">
              <input
                type="text"
                id="input-control-last-name"
                autocomplete="off"
                :disabled="!user || !user.isAdmin"
                v-model="Profile.user.last"
                class="form-control"
              />
              <span
                class="hintText position-absolute"
                v-if="!user || !user.isAdmin"
                >To change this field contact your administrator</span
              >
            </div>
          </div>

          <input-control
            type="horizontal"
            email
            required
            v-model="Profile.user.email"
            control="email"
            labelClass="col-md-3"
            >Email Address</input-control
          >
          <input-control
            type="horizontal"
            v-model="Profile.user.nickname"
            class="u-display-none"
            labelClass="col-md-3"
            >Nickname</input-control
          >
          <input-control
            type="horizontal"
            class="phoneField"
            v-model="Profile.user.phone_number"
            :cleave="cleave.phone"
            labelClass="col-md-3"
            >Primary Phone
          </input-control>

          <input-control
            type="horizontal"
            v-model="Profile.user.bio"
            control="textarea"
            labelClass="col-md-3"
            >Bio</input-control
          >
          <input-control
            v-model="Profile.user.hstNumber"
            type="horizontal"
            labelClass="col-md-3"
            >HST Number</input-control
          >
          <div class="form-group row">
            <label class="col-md-3" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="Profile.user.birth_date"
                zone="UTC"
                :week-start="7"
                placeholder=""
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <div class="form-group row mt-md-5 pt-3 ml-0 mr-0">
            <div class="col-auto px-0 px-lg-3"></div>
            <label class="ml-xl-3 mr-xl-4 mb-0"
              >Receive Notifications Via</label
            >
            <div
              class="
                d-flex
                justify-content-start
                col
                mr-2
                pl-0 pl-md-3
                mt-3 mt-md-0
              "
            >
              <div class="custom-control custom-switch ml-md-4 mr-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingSMS"
                  v-model="Profile.user.enable_sending_sms"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingSMS"
                  >SMS</label
                >
              </div>
              <div class="custom-control custom-switch ml-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingEmail"
                  v-model="Profile.user.enable_sending_email"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingEmail"
                  >Email</label
                >
              </div>
            </div>
          </div>
          <div class="offset-lg-2 col p-0 profile-action-container">
            <button
              class="mt-3 btn btn-theme"
              @click.prevent="$modal.show('change-password')"
            >
              <span
                ><i class="mr-2 fas fa-check-circle fa-key"></i>Update
                Password</span
              >
            </button>
            <button
              type="submit"
              class="offset-lg-2 mt-3 btn btn-black"
              :disabled="isSaving"
            >
              <span
                ><i class="mr-2 fas fa-check-circle fa-fw" v-if="!isSaving"></i>
                <i class="mr-2 fa fa-spin fa-circle-notch" v-else></i>Update
                Profile&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
            </button>
          </div>
        </form>
      </div>
      <div class="col-12 col-lg-4 mt-5">
        <AvatarForm
          @loaded="onLoad"
          v-bind:avatarPath="Profile.user.profile_picture"
        ></AvatarForm>
      </div>
    </div>

    <changePasswordModal />
  </div>
</template>
<script>
import { mapState } from "vuex";
import changePasswordModal from "./update-password.vue";
import { Datetime } from "vue-datetime";

export default {
  name: "ProfileUpdate",
  components: {
    changePasswordModal,
    datetime: Datetime,
  },
  data: function () {
    return {
      isSaving: false,
      cleave: {
        phone: { blocks: [3, 3, 4], delimiter: "-", numericOnly: true },
      },
    };
  },
  computed: {
    ...mapState({
      originProfile: (state) => state.Profile,
      user: (state) => state.auth.user,
    }),
    Profile: function () {
      return JSON.parse(JSON.stringify(this.originProfile));
    },
    notValidFields: function () {
      return (
        this.Profile.user.first == "" ||
        this.Profile.user.last == "" ||
        this.Profile.user.email == ""
      );
    },
  },
  methods: {
    submit() {
      this.updateProfile();
    },
    onLoad(avatar) {
      this.Profile.user.profile_picture = avatar.src;
      this.$store
        .dispatch("Profile/updatePhoto", {
          profile_picture: avatar.file,
        })
        .then((result) => {
          if (result) {
            this.$store.dispatch("Profile/get");
          }
        });
    },
    updateProfile: function () {
      let data = this.Profile.user;
      if (!this.user.isAdmin) {
        data.first = this.originProfile.user.first;
        data.last = this.originProfile.user.last;
      }
      this.originProfile.user = data;
      this.isSaving = true;
      this.$forceUpdate();
      this.$store.dispatch("Profile/update", data).then(() => {
        this.isSaving = false;
        this.$forceUpdate();
        this.$store.dispatch("Profile/get");
        this.$store.dispatch("auth/getUser");
      });
    },
  },
};
</script>

<style scoped lang="scss">
#input-control-enable-second-verification {
  transform: scale(0.5);
  width: 45px;
}
@media (max-width: 1024px) {
  .profile-outer-page-container {
    flex-direction: column-reverse;
  }
  .profile-action-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
